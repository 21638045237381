import { css, Theme } from '@emotion/react';
import dsmMediaQuery from './utils/media-queries/media-queries';

const pageStyles = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;

  ${dsmMediaQuery('medium device')} {
    grid-template-columns: repeat(8, 1fr);
    margin-left: 2rem;
    margin-right: 2rem;
  }

  ${dsmMediaQuery('large device')} {
    grid-template-columns: repeat(12, 1fr);
  }

  ${dsmMediaQuery('extra large device')} {
    max-width: 82rem;
    margin: 0 auto;
  }
`;

/* TYPOGRAPHY STYLES */
// Heading styles
const headingLargeStyles = (theme: Theme) => css`
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 3.25rem;
  color: ${theme.colors.neutral.neutral0.hex};
`;

const headingMediumStyles = (theme: Theme) => css`
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
  color: ${theme.colors.neutral.neutral0.hex};
`;

const headingSmallStyles = css`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
`;

const headingSmallBrightStyles = (theme: Theme) => css`
  ${headingSmallStyles}
  color:${theme.colors.neutral.neutral100.hex};
`;

const headingSmallDimmedStyles = (theme: Theme) => css`
  ${headingSmallStyles}
  color: ${theme.colors.neutral.neutral400.hex};
`;

const heading05Styles = (theme: Theme) => css`
  font-size: 1.25rem;
  font-weight: 700;
  color: ${theme.colors.neutral.neutral100.hex};
  line-height: 1.625rem;
`;

// Label styles
const labelStyles = (theme: Theme) => css`
  font-size: 0.75rem;
  font-weight: 700;
  color: ${theme.colors.neutral.neutral400.hex}; //#c6c7c7;
`;

const inactiveLabelStyles = (theme: Theme) => css`
  font-size: 0.75rem;
  font-weight: 400;
  color: ${theme.colors.neutral.neutral100.transparent25};
`;

// Paragraph styles
const paragraphBaseStyles = css`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
`;

const paragraphBrightStyles = (theme: Theme) => css`
  ${paragraphBaseStyles}
  color:${theme.colors.neutral.neutral100.hex};
`;

const paragraphDimmedStyles = (theme: Theme) => css`
  ${paragraphBaseStyles}
  color: ${theme.colors.neutral.neutral400.hex};
`;

const paragraphBoldStyles = (theme: Theme) => css`
  ${paragraphBaseStyles}
  color:${theme.colors.neutral.neutral100.hex};
  font-weight: 700;
`;

const textLinkStyles = (theme: Theme) => css`
  ${paragraphBaseStyles}
  text-decoration-line: underline;
  color: ${theme.colors.tertiaryText};
`;

const readMoreLinkStyles = (theme: Theme) => css`
  ${paragraphBaseStyles}
  text-decoration-line: none;
  color: ${theme.colors.tertiaryText};
`;

export {
  pageStyles,
  headingLargeStyles,
  headingMediumStyles,
  headingSmallStyles,
  headingSmallBrightStyles,
  headingSmallDimmedStyles,
  paragraphBrightStyles,
  paragraphDimmedStyles,
  paragraphBoldStyles,
  paragraphBaseStyles,
  textLinkStyles,
  readMoreLinkStyles,
  labelStyles,
  heading05Styles,
  inactiveLabelStyles,
};
