import { css, Theme } from '@emotion/react';
import dsmMediaQuery from '../../../../utils/media-queries/media-queries';

const containerStyles = css`
  grid-row: 1;
  grid-column: 1;
  display: grid;
  grid-template-areas:
    'remote'
    'button';
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  gap: 1rem;
  ${dsmMediaQuery('medium device')} {
    grid-template-areas:
      'remote remote'
      '.  button';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: max-content 1fr;
    gap: 1rem;
  }
`;

const remoteContainerStyles = (theme: Theme) => css`
  grid-area: remote;
  padding: 1rem 1rem 2.5rem 1rem;
  background-color: ${theme.colors.neutral.neutral1000.hex};
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  grid-template-rows: max-content 1fr 1fr;
  ${dsmMediaQuery('medium device')} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: max-content 1fr;
  }
`;

const textInputStyles = css`
  grid-template-areas: 'label input' '. error';
  padding-bottom: unset;
`;

const buttonContainerStyles = css`
  grid-area: button;
  display: flex;
  gap: 1rem;
  justify-content: end;
  padding-bottom: 2rem;
  flex-direction: column;
  ${dsmMediaQuery('medium device')} {
    flex-direction: row;
  }
`;

const spinnerModalStyles = (theme: Theme) => css`
  z-index: 100;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${theme.colors.primaryBackgroundTransparent};
`;

const remoteContainerContentStyles = css`
  grid-row: 2;
  grid-column: 1;
  display: grid;
  grid-template-columns: 1fr;
  padding-right: 1rem;
`;

const flowControlContainer = css`
  display: grid;
  grid-column: 1;
  grid-row: 3;
  grid-template-columns: 1fr;
  padding-right: 1rem;
  ${dsmMediaQuery('medium device')} {
    grid-row: 2;
    grid-column-start: 2;
    grid-column-end: 3;
  }
`;

const remoteHeader = css`
  grid-column-start: 1;
  grid-column-end: 4;
`;

const persistentModalParagraph = css`
  padding-bottom: 1rem;
`;

export {
  containerStyles,
  remoteContainerStyles,
  buttonContainerStyles,
  spinnerModalStyles,
  persistentModalParagraph,
  remoteContainerContentStyles,
  textInputStyles,
  flowControlContainer,
  remoteHeader,
};
