declare const window: Window & {
  env: {
    GIC_UI_ALTM_SCRIPT_URL: string;
    GIC_UI_APP_HOST: string;
    GIC_UI_APP_URL: string;
    GIC_UI_AUTH_AUTHORITY: string;
    GIC_UI_AUTH_AUTOMATIC_SILENT_RENEW: string;
    GIC_UI_AUTH_BYPASS: string;
    GIC_UI_AUTH_CLIENT_ID: string;
    GIC_UI_AUTH_LOAD_USER_INFO: string;
    GIC_UI_AUTH_POST_LOGOUT_REDIRECT_URI: string;
    GIC_UI_AUTH_REDIRECT_URI: string;
    GIC_UI_AUTH_SCOPE: string;
    GIC_UI_AUTH_RESPONSE_TYPE: string;
    GIC_UI_GRAPHQL_URL: string;
    GIC_UI_CSV_URL: string;
    GIC_UI_SENTRY_DSN: string;
    GIC_UI_ENVIRONMENT: string;
    GIC_UI_WEBPACK_ENABLE_SOURCE_MAP: string;
    GIC_UI_HOTJAR_SITE_ID: string;
    GIC_UI_FEATURE_COLUMN_CHART: string;
    VALIDATE: string;
    GIC_UI_AUTH_SILENT_REDIRECT_URI: string;
  };
};

export default window.env ?? {};
