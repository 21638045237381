import React from 'react';
import { useTranslation } from 'react-i18next';
import supportedLanguages from '../languages';
import { Divider, DropDownSelect } from '../../../components';
import { dropDownStyles } from '../styles';

const LanguageSelect = () => {
  const { t } = useTranslation();
  return (
    <>
      <DropDownSelect name="iso6391" label={t('WP.PROFILE.LANGUAGE')} css={dropDownStyles}>
        {supportedLanguages
          .sort((a, b) => a.displayText.localeCompare(b.displayText))
          .map(({ val, displayText }) => (
            <option key={val} value={val}>
              {t(displayText)}
            </option>
          ))}
      </DropDownSelect>
      <Divider />
    </>
  );
};

export default LanguageSelect;
