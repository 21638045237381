/**
 * @generated SignedSource<<c21fa955a7f416633fe58418dcf5fdfa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventCode = "CAVITATION" | "LOW_PRESSURE" | "AIR_BUBBLES" | "OVERPRESSURE" | "PREASSURE_VALVE_LEAKAGE" | "SUCTION_VALVE_LEAKAGE" | "SERVICE_NOW" | "SERVICE_SOON" | "UNDERDOSING" | "DIAPHRAGM_BREAK" | "BLOCKED_MOTOR" | "PREEMPTY_CONTAINER_FLOATER_SWITCH" | "PREEMPTY_CONTAINER_CALCULATED" | "EMPTY_CONTAINER" | "PRESSURE_SENSOR_OR_CABLE_BREAK" | "ANALOGUE_INPUT_OR_CABLE_BREAK" | "MAIN_NETWORK_COMMUNICATION_FAULT" | "CIM_HEARTBEAT_TIMEOUT" | "POWER_IN_FAIL" | "VENTING_VALVE_DEFECT" | "MOTOR_OVERHEATING" | "SENSOR_SIGNAL" | "STOP_AFTER_POWER_FAILURE" | "RTC_BATTERY_LOW" | "OVERCURRENT";
export type EventType = "ALARM" | "WARNING";
import { FragmentRefs } from "relay-runtime";
export type AlertsTileRelay_device$data = {
  readonly latestSync: {
    readonly outOfSync: boolean | null;
  } | null;
  readonly newestAlert: {
    readonly code: EventCode;
    readonly type: EventType;
  } | null;
  readonly " $fragmentType": "AlertsTileRelay_device";
};
export type AlertsTileRelay_device$key = {
  readonly " $data"?: AlertsTileRelay_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"AlertsTileRelay_device">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlertsTileRelay_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LatestSync",
      "kind": "LinkedField",
      "name": "latestSync",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outOfSync",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Event",
      "kind": "LinkedField",
      "name": "newestAlert",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "f1291f4c0ce8b73a9c2296620ef318b0";

export default node;
