import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AnalogContextProps {
  hasBeenSetToDefault: boolean;
  setHasBeenSetToDefault: (value: boolean) => void;
}

const AnalogContext = createContext<AnalogContextProps | undefined>(undefined);

export const useAnalogContext = () => {
  const context = useContext(AnalogContext);
  if (!context) {
    throw new Error('AnalogContext must be used within a AnalogContextProvider');
  }
  return context;
};

export const AnalogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [hasBeenSetToDefault, setHasBeenSetToDefault] = useState(false);
  const value = React.useMemo(() => ({ hasBeenSetToDefault, setHasBeenSetToDefault }), [hasBeenSetToDefault]);

  return <AnalogContext.Provider value={value}>{children}</AnalogContext.Provider>;
};
