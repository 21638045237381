import { css, Theme } from '@emotion/react';

const textInputStyles = css`
  grid-template-areas: 'label input' '. error';
  padding-bottom: unset;
`;

const formFieldsStyles = css`
  display: flex;
  flex-direction: column;
  .info-item-label {
    padding-left: 1rem;
  }
  .toggler-label {
    padding-left: 1rem;
  }
  label {
    padding-left: 1rem;
  }
`;

const setToDefaultContainerStyles = css`
  padding: 0.5rem 0 1rem 1rem;
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  gap: 1rem;
`;

const setToDefaultTextStyles = (theme: Theme) => css`
  color: ${theme.colors.neutral.neutral600.hex};
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
`;

const buttonEditFormStyles = css`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  height: unset;
  width: unset;
  min-width: 1rem !important;
  padding: 0;
  text-transform: unset;
`;

export { formFieldsStyles, textInputStyles, setToDefaultContainerStyles, setToDefaultTextStyles, buttonEditFormStyles };
