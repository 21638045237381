import React from 'react';
import { displayValuesContainerStyles, initialValueStyles } from './styles';

export interface DisplayValuesProps {
  initialFormValue?: string | null;
  formValue?: string | null;
  setToDefault: boolean;
  testId?: string;
}

const DisplayValues = ({ initialFormValue, formValue, setToDefault, testId }: DisplayValuesProps) => (
  <div css={displayValuesContainerStyles}>
    <div data-testid={`${testId}-initial-value`} css={initialValueStyles}>
      {setToDefault && initialFormValue !== null && initialFormValue !== formValue ? `(${initialFormValue})` : null}
    </div>
    <div data-testid={`${testId}-form-value`}>{formValue !== null ? formValue : '-'}</div>
  </div>
);

export default DisplayValues;
