import { BrowserCacheLocation, Configuration } from '@azure/msal-browser';
import env from '../env';

const scopes = env.GIC_UI_AUTH_SCOPE ? [...env.GIC_UI_AUTH_SCOPE.split(' ')] : [];

const authority = env.GIC_UI_AUTH_AUTHORITY ?? '';

// console.log('env', env);

export const oidcSettings: Configuration = {
  auth: {
    authority,
    clientId: env.GIC_UI_AUTH_CLIENT_ID ?? '',
    OIDCOptions: {
      defaultScopes: scopes,
    },
    protocolMode: 'OIDC',
    knownAuthorities: [authority],
    postLogoutRedirectUri: '/',
    redirectUri: '/callback',
    navigateToLoginRequestUrl: true,
    supportsNestedAppAuth: true,
  },
  cache: {
    storeAuthStateInCookie: true,
    secureCookies: true,
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    allowNativeBroker: false,
  },
};

export const RefreshScopes = scopes;
