import { css, Theme } from '@emotion/react';

const dialogContainer = (theme: Theme) => css`
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  padding: 1rem;
  gap: 1rem;
  max-width: 24rem;
  background: ${theme.colors.neutral.neutral1000.hex};
`;

const closeIconContainer = css`
  display: flex;
  height: fit-content;
  cursor: pointer;
`;

const svgStyles = css`
  box-sizing: unset;
`;

const headerContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const buttonDialogContainerStyles = css`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
  button {
    min-width: 100%;
  }
`;

const modalStyles = css`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: rgba(18, 21, 22, 0.7); /* Darker background */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { modalStyles, buttonDialogContainerStyles, dialogContainer, closeIconContainer, svgStyles, headerContainer };
