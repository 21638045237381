/**
 * @generated SignedSource<<49420edb253e2c768429510abecc5ebf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInfoType = "DDA" | "DDAC" | "Gateway" | "VirtualSensor";
export type EventCode = "CAVITATION" | "LOW_PRESSURE" | "AIR_BUBBLES" | "OVERPRESSURE" | "PREASSURE_VALVE_LEAKAGE" | "SUCTION_VALVE_LEAKAGE" | "SERVICE_NOW" | "SERVICE_SOON" | "UNDERDOSING" | "DIAPHRAGM_BREAK" | "BLOCKED_MOTOR" | "PREEMPTY_CONTAINER_FLOATER_SWITCH" | "PREEMPTY_CONTAINER_CALCULATED" | "EMPTY_CONTAINER" | "PRESSURE_SENSOR_OR_CABLE_BREAK" | "ANALOGUE_INPUT_OR_CABLE_BREAK" | "MAIN_NETWORK_COMMUNICATION_FAULT" | "CIM_HEARTBEAT_TIMEOUT" | "POWER_IN_FAIL" | "VENTING_VALVE_DEFECT" | "MOTOR_OVERHEATING" | "SENSOR_SIGNAL" | "STOP_AFTER_POWER_FAILURE" | "RTC_BATTERY_LOW" | "OVERCURRENT";
export type EventType = "ALARM" | "WARNING";
import { FragmentRefs } from "relay-runtime";
export type EventListItemVirtualDevice_item$data = {
  readonly createdAt: number;
  readonly device: {
    readonly uuid: string;
    readonly deviceInfo: {
      readonly type: DeviceInfoType;
    } | null;
    readonly location: {
      readonly uuid?: string;
      readonly installationName?: string;
      readonly parentLocation?: {
        readonly customerName?: string;
      } | null;
      readonly devices?: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly uuid: string;
            readonly descendantDeviceInfo: {
              readonly type: DeviceInfoType;
            } | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  };
  readonly type: EventType;
  readonly code: EventCode;
  readonly " $fragmentType": "EventListItemVirtualDevice_item";
};
export type EventListItemVirtualDevice_item$key = {
  readonly " $data"?: EventListItemVirtualDevice_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventListItemVirtualDevice_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventListItemVirtualDevice_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "deviceInfo",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": "installationName",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "parentLocation",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": "customerName",
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "type": "Section",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "DeviceConnection",
                  "kind": "LinkedField",
                  "name": "devices",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "DeviceEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Device",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": null,
                              "kind": "LinkedField",
                              "name": "descendantDeviceInfo",
                              "plural": false,
                              "selections": (v2/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Installation",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "adc06531863a07e1718b0a94d0c5d7b6";

export default node;
