import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './assets/translations/en.json';
import translationDE from './assets/translations/de-DE.json';
import translationES from './assets/translations/es.json';
import translationFR from './assets/translations/fr-FR.json';
import translationIT from './assets/translations/it-IT.json';
import translationNL from './assets/translations/nl-NL.json';
import translationPL from './assets/translations/pol.json';
import env from './env';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
  nl: {
    translation: translationNL,
  },
  pl: {
    translation: translationPL,
  },
};

i18n
  // For now we use the browser to decide which language to display.
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['navigator'] },
    resources,
    fallbackLng: 'en',
    debug: env.GIC_UI_ENVIRONMENT !== 'production',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
