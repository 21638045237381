const twoDecimals = 2; // https://grundfos.atlassian.net/browse/SDCS-2965

const rawBucketsToBucketData = (
  buckets:
    | readonly {
        readonly bucket: number;
        readonly value: number;
        readonly unit: string;
      }[]
    | undefined,
) => {
  const bucketsLength = buckets?.length || 0;
  return (buckets || []).map((bucketData, i) => {
    if (i === 0) {
      return {
        ...bucketData,
        bucket: `<${Number(bucketData?.bucket).toFixed(twoDecimals)}`,
        value: Number(bucketData.value.toFixed(twoDecimals)),
      };
    }
    if (i === bucketsLength - 1) {
      return {
        ...bucketData,
        bucket: `>${Number(bucketData?.bucket).toFixed(twoDecimals)}`,
        value: Number(bucketData.value.toFixed(twoDecimals)),
      };
    }
    return {
      ...bucketData,
      bucket: `${Number(bucketData?.bucket).toFixed(twoDecimals)}`,
      value: Number(bucketData.value.toFixed(twoDecimals)),
    };
  });
};

export default rawBucketsToBucketData;
