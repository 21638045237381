const supportedLanguages = [
  {
    val: 'bg',
    displayText: 'Български - Bulgarian',
  },
  {
    val: 'cs',
    displayText: 'Čeština - Czech',
  },
  {
    val: 'de',
    displayText: 'Deutsch - German',
  },
  {
    val: 'en',
    displayText: 'English - English',
  },
  {
    val: 'es',
    displayText: 'Español - Spanish',
  },
  {
    val: 'fr',
    displayText: 'Français - French',
  },
  {
    val: 'hr',
    displayText: 'Hrvatski - Croatian',
  },
  {
    val: 'it',
    displayText: 'Italiano - Italian',
  },
  {
    val: 'hu',
    displayText: 'Magyar - Hungarian',
  },
  {
    val: 'nl',
    displayText: 'Nederlands - Dutch',
  },
  {
    val: 'pl',
    displayText: 'Polski - Polish',
  },
  {
    val: 'pt',
    displayText: 'Português - Portuguese',
  },
  {
    val: 'ro',
    displayText: 'Română - Romanian',
  },
  {
    val: 'sk',
    displayText: 'Slovenčina - Slovak',
  },
  {
    val: 'sl',
    displayText: 'Slovenščina - Slovenian',
  },
  {
    val: 'sr',
    displayText: 'Srpski - Serbian',
  },
  {
    val: 'el',
    displayText: 'Ελληνικά – Greek',
  },
];

export default supportedLanguages;
