import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { errorFallbackStyles } from './styles';
import NoDataAvailableMessage from '../NoDataAvailableMessage/NoDataAvailableMessage';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation();

  return (
    <div css={errorFallbackStyles}>
      <div>
        <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} /> &nbsp;
        <button type="button" onClick={() => window.location.reload()}>
          Reload
        </button>
      </div>
    </div>
  );
};

export default ErrorFallback;
