import { useContext } from 'react';
import { graphql } from 'relay-hooks';
import { useMutation } from 'react-relay';
import { ControlMode, FormValues, unsupportedControlModeOptions } from '../types';
import { useSendRemoteControlCommandsMutation as RemoteControlMutation } from './__generated__/useSendRemoteControlCommandsMutation.graphql';
import { LocaleContext } from '../../../../../providers/LocaleProvider/LocaleProvider';
import { formatFormValues } from '../utils/utils';

const remoteControlDataMutation = graphql`
  mutation useSendRemoteControlCommandsMutation($input: RemoteControlInput) {
    remoteControl(input: $input) {
      result
      id
      fieldsWithErrors
    }
  }
`;

const mapLocalControlModesToServer = (values: FormValues): ControlMode | null => {
  if (unsupportedControlModeOptions.includes(values.controlMode || '')) {
    throw Error(`Unsupported control mode: '${values.controlMode}'`);
  }
  return values.controlMode;
};

const useSendRemoteControlCommands = (deviceTwinId: string | null) => {
  const [commit, isInFlight] = useMutation<RemoteControlMutation>(remoteControlDataMutation);
  const { unitSystemName } = useContext(LocaleContext);
  const sendRemoteCommands = (values: FormValues): Promise<void | readonly string[]> =>
    new Promise((resolve, reject) => {
      const formatedValues = formatFormValues(values);
      commit({
        variables: {
          input: {
            deviceTwinId,
            values: {
              ...formatedValues,
              controlMode: mapLocalControlModesToServer(values),
            },
            unitSystem: unitSystemName === 'US Unit' ? 'US' : 'EU',
          },
        },
        onCompleted: (result) => {
          if (result.remoteControl?.fieldsWithErrors && result.remoteControl?.fieldsWithErrors.length > 0) {
            // eslint-disable-next-line no-console
            console.error('[sendRemoteCommands] One or more fields could not be updated', {
              request: values,
              response: result,
            });
            // Pump accepted the request but rejected one or more fields/datapoints
            resolve(result.remoteControl.fieldsWithErrors);
          }
          // Pump accepted the request and all fields/datapoints have been updated
          resolve();
        },
        onError: (error) => {
          // The request failed. We expect nothing to be updated
          // eslint-disable-next-line no-console
          console.error('[sendRemoteCommands] Unexpected error', values, error);
          reject();
        },
      });
    });
  return { sendRemoteCommands, isLoading: isInFlight };
};

export default useSendRemoteControlCommands;
