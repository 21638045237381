import React from 'react';
import { useTheme } from '@emotion/react';
import CloseIcon from '../Icons/CloseOutline';
import Button from '../Button/Button';
import {
  modalStyles,
  buttonDialogContainerStyles,
  closeIconContainer,
  dialogContainer,
  headerContainer,
  svgStyles,
} from './styles';
import { headingSmallBrightStyles, paragraphBrightStyles } from '../../styles';

export type DialogProps = {
  title: string;
  message: React.JSX.Element | null;
  primaryButtonText: string;
  onClose: () => void;
};

const Dialog: React.FC<DialogProps> = ({ title, message, primaryButtonText, onClose }) => {
  const theme = useTheme();
  return (
    <div css={modalStyles}>
      <div css={dialogContainer}>
        <div css={headerContainer}>
          <span css={headingSmallBrightStyles}>{title}</span>
          <div css={closeIconContainer} onClick={onClose} role="button" onKeyDown={onClose} tabIndex={0}>
            <CloseIcon color={theme.colors.white.primary} size="1.5rem" styles={svgStyles} />
          </div>
        </div>
        <div css={paragraphBrightStyles}>{message}</div>
        <div css={buttonDialogContainerStyles}>
          <Button type="button" variant="primary" onClick={onClose}>
            {primaryButtonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
