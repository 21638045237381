import { RemoteControlValues } from '../hooks/__generated__/useSendRemoteControlCommandsMutation.graphql';
import { FormValues } from '../types';

// Formats the given form values by converting specific fields to numbers and returning the rest as is.
export const formatFormValues = (values: FormValues): RemoteControlValues => {
  const {
    mlPerPulse,
    manualDosingFlowValue,
    maxCapacity,
    analogDosingFlowHigher,
    analogDosingFlowLower,
    analogInputHigher,
    analogInputLower,
    ...rest
  } = values;

  const stringValues = {
    mlPerPulse,
    manualDosingFlowValue,
    maxCapacity,
    analogDosingFlowHigher,
    analogDosingFlowLower,
    analogInputHigher,
    analogInputLower,
  };

  // Fields with string values are converted to numbers
  const stringToNumberValues = Object.fromEntries(
    Object.entries(stringValues)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => [key, Number(value)]),
  );
  return {
    ...rest,
    ...stringToNumberValues,
  };
};

// Filter out analog values (default setting)
export const withoutAnalogValues = (
  formValues: FormValues,
): Omit<FormValues, 'analogInputHigher' | 'analogInputLower' | 'analogDosingFlowLower'> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { analogInputHigher, analogInputLower, analogDosingFlowLower, ...rest } = formValues;
  return rest;
};
