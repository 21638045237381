/**
 * @generated SignedSource<<6ac30ea97d724b6fa67b695a72b12b24>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeviceInfoType = "DDA" | "DDAC" | "Gateway" | "VirtualSensor";
export type EventCode = "CAVITATION" | "LOW_PRESSURE" | "AIR_BUBBLES" | "OVERPRESSURE" | "PREASSURE_VALVE_LEAKAGE" | "SUCTION_VALVE_LEAKAGE" | "SERVICE_NOW" | "SERVICE_SOON" | "UNDERDOSING" | "DIAPHRAGM_BREAK" | "BLOCKED_MOTOR" | "PREEMPTY_CONTAINER_FLOATER_SWITCH" | "PREEMPTY_CONTAINER_CALCULATED" | "EMPTY_CONTAINER" | "PRESSURE_SENSOR_OR_CABLE_BREAK" | "ANALOGUE_INPUT_OR_CABLE_BREAK" | "MAIN_NETWORK_COMMUNICATION_FAULT" | "CIM_HEARTBEAT_TIMEOUT" | "POWER_IN_FAIL" | "VENTING_VALVE_DEFECT" | "MOTOR_OVERHEATING" | "SENSOR_SIGNAL" | "STOP_AFTER_POWER_FAILURE" | "RTC_BATTERY_LOW" | "OVERCURRENT";
export type EventType = "ALARM" | "WARNING";
import { FragmentRefs } from "relay-runtime";
export type EventListItemGateway_item$data = {
  readonly createdAt: number;
  readonly device: {
    readonly uuid: string;
    readonly location: {
      readonly installationName?: string;
      readonly parentLocation?: {
        readonly customerName?: string;
      } | null;
    } | null;
    readonly deviceInfo: {
      readonly type: DeviceInfoType;
    } | null;
    readonly descendantDeviceInfo: {
      readonly type: DeviceInfoType;
    } | null;
  };
  readonly type: EventType;
  readonly code: EventCode;
  readonly " $fragmentType": "EventListItemGateway_item";
};
export type EventListItemGateway_item$key = {
  readonly " $data"?: EventListItemGateway_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventListItemGateway_item">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventListItemGateway_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Device",
      "kind": "LinkedField",
      "name": "device",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uuid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "location",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": "installationName",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "parentLocation",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": "customerName",
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "type": "Section",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "Installation",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "deviceInfo",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "descendantDeviceInfo",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "429d99732637749b4b97ffeb27937522";

export default node;
