import React, { useEffect, useState } from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay';
import { toGlobalId } from 'graphql-relay';
import { useTranslation } from 'react-i18next';
import useAuthenticatedUser from '../../hooks/useAuthenticatedUser/use-authenticated-user';
import { Snackbar } from '../../components';
import { UnitSystem } from './types';
import Profile from './Profile';
import { ProfileDataQuery } from './__generated__/ProfileDataQuery.graphql';
import { ProfileDataUpdateUserSettingsMutation } from './__generated__/ProfileDataUpdateUserSettingsMutation.graphql';
import { update18NextLanguage } from '../../providers/LocaleProvider/utils';

type SnackState = 'WP.PROFILE.PROGRESS' | 'WP.PROFILE.UPDATE_SUCCESS' | 'WP.PROFILE.UPDATE_ERROR' | undefined;

const ProfileData: React.FC = () => {
  const { t } = useTranslation();
  const user = useAuthenticatedUser();
  const userId = user?.puid;
  const [snackState, setSnackState] = useState<SnackState>();

  useEffect(() => {
    // An error snack should stay until actively dismissed
    if (snackState && snackState !== 'WP.PROFILE.UPDATE_ERROR') {
      // all other snacks should auto dimiss after a timeout
      const timer = setTimeout(() => setSnackState(undefined), 5000);
      return () => clearTimeout(timer);
    }
  }, [snackState]);

  const data = useLazyLoadQuery<ProfileDataQuery>(
    graphql`
      query ProfileDataQuery {
        me {
          id
          firstName
          lastName
          unitSystemName
          languageIso6391
        }
      }
    `,
    { id: toGlobalId('UserProfile', userId || '') },
  );

  const { me } = data;

  const [commit, isInFlight] = useMutation<ProfileDataUpdateUserSettingsMutation>(graphql`
    mutation ProfileDataUpdateUserSettingsMutation($input: UpdateUserSettingsInput) {
      updateUserSettings(input: $input) {
        id
        user {
          unitSystemName
          languageIso6391
        }
      }
    }
  `);

  return (
    <>
      {snackState && (
        <Snackbar
          message={t(snackState || '')}
          // A snack is only dismissable when its an error
          onClose={snackState === 'WP.PROFILE.UPDATE_ERROR' ? () => setSnackState(undefined) : undefined}
        />
      )}
      <Profile
        isLoading={isInFlight}
        firstName={me?.firstName || ''}
        lastName={me?.lastName || ''}
        unitSystemName={me?.unitSystemName || ''}
        languageIso6391={me?.languageIso6391 || ''}
        onSubmit={({ unitSystem, iso6391 }) => {
          setSnackState('WP.PROFILE.PROGRESS');
          commit({
            variables: {
              input: {
                unitSystem: unitSystem === 'US Unit' ? UnitSystem.US : UnitSystem.EU,
                iso6391,
              },
            },
            onCompleted: (response) => {
              setSnackState('WP.PROFILE.UPDATE_SUCCESS');
              // Update lanaguage
              if (response?.updateUserSettings?.user?.languageIso6391)
                update18NextLanguage(response?.updateUserSettings?.user?.languageIso6391);
            },
            onError: (err) => {
              setSnackState('WP.PROFILE.UPDATE_ERROR');
              // eslint-disable-next-line no-console
              console.error(err);
            },
          });
        }}
      />
    </>
  );
};

export default ProfileData;
