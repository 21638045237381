import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import {
  FormValues,
  PumpStateOptions,
  SlowModeOptions,
  supportedControlModeOptions,
  unsupportedControlModeOptions,
  getFormValuesKey,
} from '../types';
import {
  buttonContainerStyles,
  containerStyles,
  flowControlContainer,
  remoteContainerContentStyles,
  remoteContainerStyles,
  remoteHeader,
  persistentModalParagraph,
} from '../styles';
import { paragraphBrightStyles } from '../../../../../styles';
import { buttonEditFormStyles } from './styles';
import { Header, Section, StoppedLocally, Message } from '../components';
import { Button, Dialog, Divider, DropDownSelect, Snackbar, TogglerFormik } from '../../../../../components';
import { EditFormProps } from './types';
import MaxCapacity from './MaxCapacity';
import settings from '../../../../../assets/images/settings.svg';
import { withoutAnalogValues } from '../utils/utils';
import { useAnalogContext } from '../AnalogProvider';

const HIDE_AFTER_DELAY_INTERVAL = 5000;

const PersistentMessage: React.FC<{ onReadMoreClick: () => void }> = ({ onReadMoreClick }) => {
  const { t } = useTranslation();
  return (
    <Message
      iconType={settings}
      message={
        <div css={paragraphBrightStyles}>
          {`${t('WP.REMOTE.PERSISTENT_WARNING_TEXT')} `}
          <Button css={buttonEditFormStyles} type="button" variant="text link" onClick={onReadMoreClick}>
            {t('WP.REMOTE.PERSISTENT_WARNING_READ_MORE')}
          </Button>
        </div>
      }
    />
  );
};

const EditForm: React.FC<EditFormProps> = ({
  VariantMessage,
  persistentMode,
  validationSchema,
  data,
  handleFormSubmit,
  FlowControlForm,
  isStoppedByOtherThanRemote,
  disabled = false,
  DosingControlForm,
  maxCapacityUnit,
}) => {
  const { t } = useTranslation();
  const [snackbarMessage, setSnackbarMessage] = useState<
    'WP.REMOTE.UPDATE_IN_PROGRESS' | 'WP.REMOTE.UPDATE_SUCCESS' | 'WP.REMOTE.UPDATE_ERROR' | undefined
  >(undefined);
  const hideAfterDelay = (delay: number) => setTimeout(() => setSnackbarMessage(undefined), delay);
  const snackBarIsDismissable = snackbarMessage !== 'WP.REMOTE.UPDATE_IN_PROGRESS';
  const onClose = snackBarIsDismissable ? () => setSnackbarMessage(undefined) : undefined;
  const [showDialog, setShowDialog] = useState(false);
  const { hasBeenSetToDefault, setHasBeenSetToDefault } = useAnalogContext();

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleReadMoreClick = () => {
    setShowDialog(true);
  };

  const persistentModalText = () => (
    <>
      <div css={persistentModalParagraph}>{t('WP.REMOTE.PERSISTENT_MODAL_TEXTA')}</div>
      <div css={persistentModalParagraph}>{t('WP.REMOTE.PERSISTENT_MODAL_TEXTB')}</div>
    </>
  );

  return (
    <>
      {snackbarMessage && (
        <Snackbar testId="edit-form-snackbar" message={snackbarMessage && t(snackbarMessage)} onClose={onClose} />
      )}
      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={async (values, actions) => {
          setSnackbarMessage('WP.REMOTE.UPDATE_IN_PROGRESS');

          try {
            // We filter out analog values (analogInputLower, analogDosingFlowLower, analogInputHigher)
            // unless the user has chosen to reset the values to proportional values.
            const filteredValues = withoutAnalogValues(values);
            const fieldsWithErrors = hasBeenSetToDefault
              ? await handleFormSubmit(values)
              : await handleFormSubmit(filteredValues);
            if (fieldsWithErrors && fieldsWithErrors.length > 0) {
              // Reset all fields with error to our latest known pump state from data
              fieldsWithErrors.forEach((fieldName) =>
                actions.setFieldValue(fieldName, data[fieldName as keyof FormValues]),
              );

              setHasBeenSetToDefault(false);
              setSnackbarMessage('WP.REMOTE.UPDATE_ERROR');
            } else {
              setSnackbarMessage('WP.REMOTE.UPDATE_SUCCESS');
              hideAfterDelay(HIDE_AFTER_DELAY_INTERVAL);
              /* Since we have no errors we assume success in resetting analog
                values from unproportional to proportional. We use this status to
                switch the analog component from unproportional to proportional
                straight away. */
              if (hasBeenSetToDefault) {
                actions.setStatus({ setToDefaultSuccess: true });
              } else {
                actions.setStatus({ setToDefaultSuccess: false });
              }
            }
          } catch {
            setSnackbarMessage('WP.REMOTE.UPDATE_ERROR');
            setHasBeenSetToDefault(false);
            actions.resetForm({
              values: data,
            });
          }
        }}
      >
        {({ values, setFieldValue, isValid }) => (
          <Form css={containerStyles}>
            <div css={remoteContainerStyles}>
              <div css={remoteHeader}>
                {Boolean(VariantMessage) && VariantMessage}
                {persistentMode && <PersistentMessage onReadMoreClick={handleReadMoreClick} />}
                <Header title={t('WP.REMOTE.REMOTE')} controlMode={t(`WP.REMOTE.${values.controlMode}`)} />
              </div>
              <div css={remoteContainerContentStyles}>
                <div>
                  <Section
                    css={css`
                      padding-bottom: 2rem;
                      padding-top: 5rem;
                    `}
                  >
                    <DropDownSelect
                      testId="pump-state"
                      disabled={isStoppedByOtherThanRemote}
                      name={getFormValuesKey('pumpState')}
                      label={t('WP.REMOTE.DOSING_PUMP')}
                    >
                      {Object.keys(PumpStateOptions).map((option) => (
                        <option key={option} value={option}>
                          {t(`WP.REMOTE.${option}`)}
                        </option>
                      ))}
                    </DropDownSelect>
                    <Divider />
                    <StoppedLocally isStoppedLocally={isStoppedByOtherThanRemote} />
                  </Section>
                  <Section title={t('WP.REMOTE.CONTROL_MODE_SETTINGS')}>
                    <MaxCapacity unit={maxCapacityUnit} />
                    <Divider />
                    <DropDownSelect
                      testId="control-mode"
                      onSelectValue={() => {
                        // We want to make sure that we stop the pump when doing control mode changes
                        // this will keep the remote control pump behaviour consistant with physical pump behavour
                        // The user can select the pump state to be START.
                        // Then the pump will start itself after changing the control mode
                        setFieldValue(getFormValuesKey('pumpState'), PumpStateOptions.STOP);

                        // When changing control mode we want to reset any input made by the user in the now hidden input field.
                        // We do this to avoid sending out of range and unintended input values to the pump
                        setFieldValue(getFormValuesKey('manualDosingFlowValue'), data.manualDosingFlowValue);
                        setFieldValue(getFormValuesKey('mlPerPulse'), data.mlPerPulse);
                        setFieldValue(getFormValuesKey('pulseMemory'), data.pulseMemory);
                        setFieldValue(getFormValuesKey('analogDosingFlowHigher'), data.analogDosingFlowHigher);
                        setFieldValue(getFormValuesKey('analogInputLower'), data.analogInputLower);
                        setFieldValue(getFormValuesKey('analogDosingFlowLower'), data.analogDosingFlowLower);
                        setFieldValue(getFormValuesKey('analogInputHigher'), data.analogInputHigher);
                      }}
                      name={getFormValuesKey('controlMode')}
                      label={t('WP.REMOTE.CONTROL_MODE')}
                    >
                      {unsupportedControlModeOptions.includes(values.controlMode || '') && (
                        <>
                          <option disabled key={1} value="WEEK_TIMER_BATCH">
                            {t('WP.REMOTE.TIMER')}
                          </option>
                          <option disabled key="INTERVAL_BATCH" value="INTERVAL_BATCH">
                            {t('WP.REMOTE.BATCH')}
                          </option>
                          <option disabled key="PULSE_BATCH" value="PULSE_BATCH">
                            {t('WP.REMOTE.BATCH')}
                          </option>
                          <option disabled key="EXTERNAL_PULSE_BATCH" value="EXTERNAL_PULSE_BATCH">
                            {t('WP.REMOTE.BATCH')}
                          </option>
                        </>
                      )}
                      {supportedControlModeOptions.map((option) => (
                        <option key={option} value={option}>
                          {t(`WP.REMOTE.${option}`)}
                        </option>
                      ))}
                    </DropDownSelect>
                    <Divider />
                    <div>{DosingControlForm}</div>
                  </Section>
                </div>
              </div>
              <div css={flowControlContainer}>
                <div>
                  <Section title={t('WP.REMOTE.FLOW_CONTROL_TITLE')}>
                    <TogglerFormik
                      testId="auto-dearation"
                      label={t('WP.REMOTE.AUTO_DEAERATION')}
                      name={getFormValuesKey('autoDeaeration')}
                      isToggled={values.autoDeaeration}
                    />
                    <Divider />
                    <DropDownSelect
                      testId="slow-mode"
                      name={getFormValuesKey('slowMode')}
                      label={t('WP.REMOTE.SLOW_MODE')}
                    >
                      {Object.entries(SlowModeOptions).map(([key, value]) => (
                        <option key={key} value={key}>
                          {t(`WP.REMOTE.SLOW_MODEL_OPTION_${value}`)}
                        </option>
                      ))}
                    </DropDownSelect>
                    <Divider />
                    <TogglerFormik
                      testId="stop-after-power-failure"
                      label={t('WP.REMOTE.STOP_AFTER_POWER_FAILURE')}
                      name={getFormValuesKey('stopAfterPowerFailure')}
                      isToggled={values.stopAfterPowerFailure}
                    />
                    <Divider />
                    {FlowControlForm}
                  </Section>
                </div>
              </div>
            </div>
            <div css={buttonContainerStyles}>
              <Button testId="send-remote-command" type="submit" variant="primary" disabled={disabled || !isValid}>
                {t('WP.REMOTE.SEND')}
              </Button>
            </div>
            {showDialog && (
              <Dialog
                title={t('WP.REMOTE.PERSISTENT_MODAL_HEADING')}
                message={persistentModalText()}
                primaryButtonText={t('WP.REMOTE.PERSISTENT_MODAL_OK')}
                onClose={handleDialogClose}
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditForm;
